import axios from "axios";

import { formatLiveForm, generalFormat } from "./helperFunctions";
const baseUrl = require("../../shared.js").default.apiBaseUrl;
const { nonPersistentStore } = require("../../store/store");
const { state } = nonPersistentStore;

const createLiveForm = async (createData) => {
  try {
    const urlToQuery = `${baseUrl}/live-form`;
    const axiosResponse = await axios.post(urlToQuery, createData);
    const { data } = axiosResponse;
    return formatLiveForm(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllLiveFormsOfBand = async (bandId) => {
  try {
    const urlToQuery = `${baseUrl}/live-forms?bandId=${bandId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data.map((liveForm) => formatLiveForm(liveForm));
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getAllPublicLiveFormsOfBand = async (bandId) => {
  try {
    const urlToQuery = `${baseUrl}/live-forms/public?bandId=${bandId}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return data.map((liveForm) => formatLiveForm(liveForm));
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getLiveForm = async ({ bandId, liveFormPageName }) => {
  try {
    const urlToQuery = `${baseUrl}/live-form?bandId=${bandId}&liveFormPageName=${encodeURIComponent(
      liveFormPageName
    )}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return formatLiveForm(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const updateLiveForm = async (liveFormId, editData) => {
  try {
    const urlToQuery = `${baseUrl}/live-form?id=${liveFormId}`;
    const axiosResponse = await axios.patch(urlToQuery, editData);
    const { data } = axiosResponse;
    return formatLiveForm(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const createLiveFormTransaction = async (createData) => {
  try {
    const urlToQuery = `${baseUrl}/live-form-transaction`;
    const axiosResponse = await axios.post(urlToQuery, createData);
    const { data } = axiosResponse;
    return generalFormat(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getLiveFormTransaction = async (liveFormTransactionUUID) => {
  try {
    const urlToQuery = `${baseUrl}/live-form-transaction?uuid=${liveFormTransactionUUID}`;
    const axiosResponse = await axios.get(urlToQuery);
    const { data } = axiosResponse;
    return generalFormat(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

const getLiveFormTransactions = async ({
  bandId,
  liveFormId,
  forceRefresh = false,
}) => {
  try {
    let axiosEndpoint;

    if (!bandId && !liveFormId) {
      throw new Error("Missing bandId or liveFormId");
    } else if (bandId) {
      axiosEndpoint = `${baseUrl}/live-form-transactions?bandId=${bandId}`;
    } else if (liveFormId) {
      const currentLiveFormTransactionsInStore =
        state.currentLiveFormTransactions;
      if (
        currentLiveFormTransactionsInStore &&
        currentLiveFormTransactionsInStore.liveFormId === liveFormId &&
        !forceRefresh
      ) {
        return currentLiveFormTransactionsInStore.values;
      }
      axiosEndpoint = `${baseUrl}/live-form-transactions?liveFormId=${liveFormId}`;
    }

    if (forceRefresh) {
      axiosEndpoint += `&initiatedAt=${new Date().toJSON()}`;
    }

    const response = await axios.get(axiosEndpoint);
    const data = response.data;
    const sortedTransactions = data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    const formattedLiveFormTransactions = sortedTransactions.map(
      (liveFormTransaction) => generalFormat(liveFormTransaction)
    );
    if (liveFormId) {
      nonPersistentStore.commit("addOrUpdateLiveFormTransactionsData", {
        liveFormId,
        values: formattedLiveFormTransactions,
      });
    }
    return formattedLiveFormTransactions;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const updateLiveFormTransaction = async (liveFormTransactionId, editData) => {
  try {
    const urlToQuery = `${baseUrl}/live-form-transaction?id=${liveFormTransactionId}`;
    const axiosResponse = await axios.patch(urlToQuery, editData);
    const { data } = axiosResponse;
    return generalFormat(data);
  } catch (err) {
    console.log(err);
    return false;
  }
};

export {
  createLiveForm,
  getAllLiveFormsOfBand,
  getAllPublicLiveFormsOfBand,
  getLiveForm,
  updateLiveForm,
  createLiveFormTransaction,
  getLiveFormTransaction,
  getLiveFormTransactions,
  updateLiveFormTransaction,
};
