import { getAuth } from "firebase/auth";
import Vue from "vue";
import VueRouter from "vue-router";

import { nonPersistentStore } from "../store/store";

// statically-imported routes
const Home = () => import("../views/p/Home.vue");
const SelectAccount = () => import("../views/p/SelectAccount");
const Login = () => import("../views/p/Log-in.vue");
const Directory = () => import("../views/p/Directory.vue");
const BandSearch = () => import("../views/listener/BandSearch");
const GigLanding = () => import("../views/listener/GigLanding");
const GigPage = () => import("../views/listener/GigPage");
const Upcoming = () => import("../views/listener/Upcoming");
const Venue = () => import("../views/listener/Venue");
const VenueStage = () => import("../views/listener/VenueStage");
const BandProfile = () => import("../views/listener/BandProfile");
const SouthBend = () => import("../views/listener/SouthBend.vue");
const TitansPage = () => import("../views/listener/Titans/TitansPage.vue");
const KidzBopPage = () => import("../views/listener/KidzBop/KidzBopPage.vue");
// const TitansLanding = () =>
//   import("../views/listener/Titans/TitansLanding.vue");
const SignUp = () => import("../views/p/SignUp.vue");
const MatchboxEvent = () => import("../views/listener/Matchbox/MatchboxEvent");
const MatchboxEventLanding = () =>
  import("../views/listener/Matchbox/MatchboxEventLanding");
const Experiences = () => import("../views/p/Experiences");
const SongBattles = () => import("../views/p/SongBattles");
const RestorePurchases = () => import("../views/listener/RestorePurchases");
const ChooseView = () => import("../views/p/ChooseView");
const SpotifyConnect = () => import("../views/p/SpotifyConnect");
const SpotifySearch = () => import("../views/p/SpotifySearch");
const LegendsShowList = () =>
  import("../views/listener/Legends/LegendsShowList");
const Fan = () => import("../views/p/Fan");
const CustomSearchPage = () => import("../views/listener/CustomSearchPage");
const BandMerchandise = () => import("../views/listener/BandMerchandise");
const Poll = () => import("../views/listener/Poll");
const SongBattle = () => import("../views/listener/SongBattle");
const LiveForm = () => import("../views/listener/LiveForm");
const LiveFormConfirmation = () =>
  import("../views/listener/LiveFormConfirmation");

// Auth Views
const BandInfo = () => import("../views/p/BandInfo");
const SetList = () => import("../views/p/SetList");
const SetListMenu = () => import("../views/p/SetListMenu");
const Schedule = () => import("../views/p/Schedule");
const Requests = () => import("../views/p/Requests");
const Settings = () => import("../views/p/Settings");
const Analytics = () => import("../views/p/Analytics");
const Resources = () => import("../views/p/Resources");
const DynamicLinks = () => import("../views/p/DynamicLinks");
const Customers = () => import("../views/p/Customers.vue");
const SuperDashboard = () => import("../views/p/SuperDashboard");
const BandCreation = () => import("../views/p/BandCreation");
const Merchandise = () => import("../views/p/Merchandise");
const Events = () => import("../views/p/Events");
const LiveFeedTiles = () => import("../views/p/LiveFeedTiles");
const SongRequestTile = () => import("../views/p/SongRequestTile");
const SuperfanSettings = () => import("../views/p/SuperfanSettings");
const SuperfanPost = () => import("../views/p/SuperfanPost");
const Followers = () => import("../views/p/Followers");
const Following = () => import("../views/p/Following");
const LiveForms = () => import("../views/p/LiveForms");
const LiveFormTransactions = () => import("../views/p/LiveFormTransactions");

const AccountSettings = () => import("../views/p/AccountSettings");
const ManageSubscriptions = () => import("../views/p/ManageSubscriptions");
const EditProfile = () => import("../views/p/EditProfile");
const ChangePassword = () => import("../views/p/ChangePassword");

// No-Auth/Listener views
const Livecast = () => import("../views/p/Livecast");

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect: "/search",
      meta: {},
    },
    {
      path: "/",
      redirect: "/search",
      meta: {},
    },
    {
      path: "/southbend",
      component: SouthBend,
    },
    {
      path: "/nd/:pageName",
      component: CustomSearchPage,
    },
    {
      path: "/sp/:pageName",
      component: CustomSearchPage,
    },
    {
      path: "/kidzbop/schedule",
      component: KidzBopPage,
    },
    {
      path: "/titans/schedule",
      component: TitansPage,
    },
    {
      path: "/legendsbranson/shows",
      component: LegendsShowList,
    },
    {
      name: "Restore Purchases",
      component: RestorePurchases,
      path: "/restore-purchases",
    },
    {
      path: "/event/matchbox20/landing",
      component: MatchboxEventLanding,
      name: "Matchbox Event Landing",
    },
    {
      path: "/event/matchbox20",
      component: MatchboxEvent,
      name: "Matchbox Event",
    },
    {
      path: "/event/matchbox20/ty/:votes",
      component: MatchboxEvent,
      name: "Matchbox Event TY",
    },
    {
      path: "/v/:venueId",
      component: Venue,
      name: "Venue",
      meta: {},
    },
    {
      path: "/v/:venueId/:stageId",
      component: VenueStage,
      name: "Venue Stage",
      meta: {},
    },
    {
      path: "/p/",
      redirect: "/p/login",
      meta: {},
    },
    {
      path: "/p/login",
      name: "Login",
      component: Login,
      layout: "juke-ui",
      meta: {},
    },
    {
      path: "/p/sign-up",
      name: "SignUp",
      component: SignUp,
      meta: {
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/p/song-request-tile",
      name: "Song Request Tile",
      component: SongRequestTile,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/live-feed-tiles",
      name: "Live Feed Tiles",
      component: LiveFeedTiles,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/band-creation",
      name: "Band Creation",
      component: BandCreation,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/p/home",
      name: "Home",
      component: Home,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/song-battles",
      name: "Song Battles",
      component: SongBattles,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/polls",
      name: "Polls",
      component: Events,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/merchandise",
      name: "Merchandise",
      component: Merchandise,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/experiences",
      name: "Experiences",
      component: Experiences,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/resources",
      name: "Resources",
      component: Resources,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/dynamic-links",
      name: "Dynamic Links",
      component: DynamicLinks,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/live-forms",
      name: "Live Forms",
      component: LiveForms,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/band-info",
      name: "Band Info",
      component: BandInfo,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/customers",
      name: "Customers",
      component: Customers,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/analytics",
      name: "Analytics",
      component: Analytics,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/songlist/:setlistId",
      name: "Songlist",
      component: SetList,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/songlist-menu",
      name: "Songlist Menu",
      component: SetListMenu,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/schedule",
      name: "Schedule",
      component: Schedule,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/requests",
      name: "Requests",
      component: Requests,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/settings",
      name: "Settings",
      component: Settings,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/choose-view",
      name: "Choose View",
      component: ChooseView,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
        requiresProgrammaticAccess: true,
      },
    },
    {
      path: "/p/fan",
      name: "Fan",
      component: Fan,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/p/account-settings",
      name: "Account Settings",
      component: AccountSettings,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/p/subscriptions",
      name: "Subscriptions",
      component: ManageSubscriptions,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
        requiresProgrammaticAccess: true,
      },
    },
    {
      path: "/p/edit-profile",
      name: "Edit Profile",
      component: EditProfile,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
        requiresProgrammaticAccess: true,
      },
    },
    {
      path: "/p/change-password",
      name: "Change Password",
      component: ChangePassword,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
        requiresProgrammaticAccess: true,
      },
    },
    {
      path: "/p/select-account",
      name: "Select Account",
      component: SelectAccount,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/p/livecast",
      name: "Juke Livecast1",
      component: Livecast,
      meta: {
        requiresFirebaseUser: true,
        livecast: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/super-dashboard",
      name: "Team Dashboard",
      component: SuperDashboard,
      meta: {
        requiresFirebaseUser: true,
        requiresAdmin: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/directory",
      name: "Directory",
      component: Directory,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/superfan-settings",
      name: "Superfan Settings",
      component: SuperfanSettings,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/p/followers",
      name: "Followers",
      component: Followers,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/p/following",
      name: "Following",
      component: Following,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/p/superfan-post",
      name: "Superfan Post",
      component: SuperfanPost,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/p/live-form-transactions/:liveFormPageName",
      name: "LiveFormTransactions",
      component: LiveFormTransactions,
      meta: {
        requiresFirebaseUser: true,
        requiresSelectedBand: true,
      },
    },
    {
      path: "/p/spotify-connect",
      name: "Spotify Connect",
      component: SpotifyConnect,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/p/spotify-search",
      name: "Spotify Search",
      component: SpotifySearch,
      meta: {
        requiresFirebaseUser: true,
        hideTopAndBotNav: true,
      },
    },
    {
      path: "/search",
      name: "Find Live Music",
      component: BandSearch,
      meta: {},
    },
    // bandName stuff
    {
      path: "/:bandName/livecast",
      name: "Juke Livecast2",
      component: Livecast,
      meta: {
        livecast: true,
      },
    },
    {
      path: "/:bandName/upcoming",
      name: "Upcoming",
      component: Upcoming,
    },
    // {
    //   path: "/titans/:pageName/landing",
    //   name: "Titans Landing",
    //   component: TitansLanding,
    //   meta: {},
    // },
    {
      path: "/:bandName/:pageName/landing",
      name: "Gig Landing",
      component: GigLanding,
      meta: {
        transition: "slide-left",
      },
    },
    {
      path: "/:bandName/:pageName/landing/ty-shoutout",
      name: "Thank You Shoutout",
      component: GigLanding,
      meta: {},
    },
    {
      component: BandMerchandise,
      path: "/:bandName/merchandise",
      name: "Band Merchandise",
    },
    {
      component: BandMerchandise,
      path: "/:bandName/merchandise/ty",
      name: "Band Merchandise Thank You",
    },
    {
      path: "/:bandName/:pageName",
      name: "Juke",
      component: GigPage,
      meta: {
        transition: "slide-left",
      },
    },
    {
      name: "SongBattle",
      path: "/:bandName/:pageName/song-battle/:gigSongBattleId",
      component: SongBattle,
    },
    {
      name: "Poll",
      path: "/:bandName/:pageName/poll/:gigEventId",
      component: Poll,
    },
    {
      name: "LiveForm",
      path: "/:bandName/live-forms/:liveFormPageName",
      component: LiveForm,
    },
    {
      name: "LiveFormConfirmation",
      path: "/:bandName/live-form-confirmation/:liveFormPageName/:liveFormTransactionUUID",
      component: LiveFormConfirmation,
    },
    {
      path: "/:bandName/:pageName/ty/:amount",
      name: "Thank You",
      component: GigPage,
      meta: {},
    },
    {
      path: "/:bandName/:pageName/ty-tip/:amount",
      name: "Thank You Tip",
      component: GigLanding,
      meta: {},
    },
    {
      path: "/:bandName/:pageName/songbattle-ty/:amount",
      name: "Thank You Song Battle",
      component: GigPage,
      meta: {},
    },
    {
      path: "/:bandName/:pageName/ty-songpass",
      name: "Thank You Song Pass",
      component: GigPage,
      meta: {},
    },
    {
      path: "/:bandName",
      component: BandProfile,
      meta: {
        transition: "slide-left",
      },
    },
    {
      path: "/:bandName/ty/:votes",
      component: BandProfile,
      name: "Band Profile Thank You",
      meta: {
        transition: "slide-left",
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const toRecord = to.matched;

  const fromQuery = from?.query || {};
  const toQuery = to?.query || {};

  let finalToRecord = null;

  let needsUTMInNextQuery = false;
  const hasUtmInFromQuery = Object.keys(fromQuery).some((key) =>
    key.includes("utm_")
  );
  if (hasUtmInFromQuery) {
    // assemble new to object including all of the keys with _utm in them
    // dont include the ones that are already in the toQuery
    const utmKeys = Object.keys(fromQuery).filter((key) =>
      key.includes("utm_")
    );
    const newToQuery = { ...to };
    if (!newToQuery.query) {
      newToQuery.query = {};
    }
    for (const key of utmKeys) {
      if (!toQuery[key]) {
        newToQuery.query[key] = fromQuery[key];
        needsUTMInNextQuery = true;
      }
    }
    finalToRecord = newToQuery;
  }

  // current firebase user
  const currentFirebaseUser = getAuth().currentUser;
  // check to make sure the currently logged in band isn't empty
  const currentlyLoggedInBand =
    nonPersistentStore.state.currentlyLoggedInBand &&
    nonPersistentStore.state.currentlyLoggedInBand.ID;
  const isAdmin = nonPersistentStore.getters.isAdmin;

  const requiresFirebaseUser = toRecord.some(
    (record) => record.meta.requiresFirebaseUser
  );
  const requiresSelectedBand = toRecord.some(
    (record) => record.meta.requiresSelectedBand
  );
  const requiresAdmin = toRecord.some((record) => record.meta.requiresAdmin);

  // Flag to check if route should be accessible only via router.push
  const requiresProgrammaticAccess = toRecord.some(
    (record) => record.meta.requiresProgrammaticAccess
  );

  // Ensure programmatic navigation with router.push by checking the "from" route
  const isProgrammaticNavigation = !!from.name;
  const isProgrammaticFlag =
    nonPersistentStore.getters.isProgrammaticNavigation; // Get flag from Vuex

  // the order of checking is: requiresFirebaseUser -> requiresSelectedBand -> requiresAdmin -> requiresProgrammaticAccess

  if (requiresFirebaseUser && !currentFirebaseUser) {
    // needs to login
    next("/p/login");
  } else if (requiresSelectedBand && !currentlyLoggedInBand) {
    const { path } = to;
    next(`/p/select-account?after=${encodeURI(path)}`);
  } else if (requiresAdmin && !isAdmin) {
    // trying to access something they shouldn't be
    next("/p/home");
  } else if (
    requiresProgrammaticAccess &&
    !isProgrammaticNavigation &&
    !isProgrammaticFlag
  ) {
    // Block direct access to the route (not navigated from a valid route)
    next("/search");
  } else {
    nonPersistentStore.commit("setProgrammaticNavigation", false); // Set flag to false
    if (needsUTMInNextQuery) {
      next(finalToRecord);
    } else {
      next();
    }
  }
});

export default router;
